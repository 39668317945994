import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import appwrite from "../../Assets/Projects/appwrite.png";
import magic from "../../Assets/Projects/magic.png";
import gabi from "../../Assets/Projects/gabi.png";
import ariscopo from "../../Assets/Projects/ariscopo.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={appwrite}
              isBlog={false}
              isRepo={true}
              title="AppWrite Example"
              description="Code snippets for the community of how to use this Backend Service with self host configurations and Native Android Development with Jetpack Compose."
              ghLink="https://github.com/DerSarco/appwrite-example"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={magic}
              isBlog={false}
              isRepo={true}
              title="Magic Cards List"
              description="Project made in Jetpack Compose, Unit Testing, API Calls and many more. This was built for a position interview for a international company."
              ghLink="https://github.com/DerSarco/MagicCards"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gabi}
              isYoutube={true}
              isBlog={false}
              isRepo={false}
              title="Gabi Moreno Android Podcast
               Video Editor"
              description="Video edition, audio processing and rendering of video podcast of Gabi Moreno's community Los Androides."
              ytLink="https://www.youtube.com/@soygabimoreno"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ariscopo}
              isYoutube={false}
              isBlog={false}
              isRepo={true}
              title="NewAstro API"
              description="API Built for the AristiDev's community for a free Android course, it has some funny stuff from the community but is a functional API"
              ghLink="https://github.com/DerSarco/newastro"
              demoLink="https://newastro.vercel.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
